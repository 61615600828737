import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import BusinessCardsImg from "../../components/images/printing/business-cards"

export default function BusinessCards() {
    return (
        <>
            <Layout>
                <SEO title="Business Cards" />
                <Display
                    title="Business Cards"
                    description="Business cards will always be in style. Choose from a wide selection of classic or trendy designs and styles that will keep your business top of mind."
                    prev="/printing/posters-signage"
                    next="/printing/office-stationary"
                    image={<BusinessCardsImg />}
                />
            </Layout>                
        </>
    )
}
